import Home from './pages.js/home';
import './App.css';

function App() {
  return (
    < Home />
  );
}

export default App;
